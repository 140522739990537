import Word from "./Word.js"
import { FiTrash2 } from "react-icons/fi"

export default function Grid(props) {
	function handleClear(event) {
		event.preventDefault()
		props.handleClear()
	}

	return (
		<div className="flex flex-row justify-content-center items-center gap-x-4 pl-12">
			<div className="flex flex-col gap-y-2 bg-slate-800 p-4 drop-shadow-none rounded-xl">
				{props.attempts && props.attempts.map((attempt, i) => <Word word={attempt} key={i} />)}

				{props.attempts.length < 6 && <Word current={true} />}
				{props.attempts.length < 6 && [...Array(6 - props.attempts.length - 1)].map((_, i) => <Word key={i} />)}
			</div>
			<FiTrash2
				className="w-8 h-8 text-red-600 cursor-pointer"
				onClick={(event) => handleClear(event)}
			></FiTrash2>
		</div>
	)
}
