export default function Letter(props) {
	return (
		<>
			<div
				className={`w-12 h-14 ${
					props.exists ? (props.position ? "bg-green-600" : "bg-yellow-600") : "bg-slate-600"
				} flex justify-center items-center font-bold text-orange-100 text-3xl uppercase rounded-xl`}
			>
				{props.letter}
			</div>
		</>
	)
}
