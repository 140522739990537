import React, { useState, useRef, useEffect } from "react";
import { FiRotateCcw, FiTrash2, FiSend } from "react-icons/fi";

export default function Input(props) {
  let defaultInput = [
    {
      letter: "",
      exists: false,
      position: false,
    },
    {
      letter: "",
      exists: false,
      position: false,
    },
    {
      letter: "",
      exists: false,
      position: false,
    },
    {
      letter: "",
      exists: false,
      position: false,
    },
    {
      letter: "",
      exists: false,
      position: false,
    },
  ];

  const [input, setInput] = useState(defaultInput);

  const [allowSubmit, setAllowSubmit] = useState(false);

  const input0Ref = useRef();
  const input1Ref = useRef();
  const input2Ref = useRef();
  const input3Ref = useRef();
  const input4Ref = useRef();

  const submitRef = useRef();

  useEffect(() => {
    input0Ref.current.focus();
  }, []);

  useEffect(() => {
    if (props.inputPass) {
      let inputPass = [...input];
      inputPass.forEach((cell, i) => {
        cell.letter = props.inputPass[i].toUpperCase();
      });
      setInput(inputPass);
      validateInput();
    }
	
  }, [props.inputPass]);
  function validateInput() {
    let allow = true;
    input.forEach((cell) => {
      if (
        cell.letter != "" &&
        cell.letter.length == 1 &&
        cell.letter.toUpperCase() != cell.letter.toLowerCase()
      ) {
        allow = allow && true;
      } else {
        allow = false;
      }
    });
    setAllowSubmit(allow);
  }

  function handleInputChange(event, index) {
    event.preventDefault();
    let newInput = input;
    newInput[index].letter = event.target.value.toUpperCase();
    setInput([...newInput]);
    if (event.target.value === "") {
      if (index == 0) {
        input0Ref.current.focus();
      } else if (index == 1) {
        input1Ref.current.focus();
      } else if (index == 2) {
        input2Ref.current.focus();
      } else if (index == 3) {
        input3Ref.current.focus();
      } else if (index == 4) {
        input3Ref.current.focus();
      }
    } else {
      if (index == 0) {
        input1Ref.current.focus();
      } else if (index == 1) {
        input2Ref.current.focus();
      } else if (index == 2) {
        input3Ref.current.focus();
      } else if (index == 3) {
        input4Ref.current.focus();
      } else if (index == 4) {
        submitRef.current.focus();
      }
    }
    validateInput();
  }

  function handleReset(event, index) {
    event.preventDefault();
    let newInput = input;
    newInput[index].letter = "";
    newInput[index].exists = false;
    newInput[index].position = false;
    setInput([...newInput]);
    if (index == 0) {
      input0Ref.current.focus();
    } else if (index == 1) {
      input1Ref.current.focus();
    } else if (index == 2) {
      input2Ref.current.focus();
    } else if (index == 3) {
      input3Ref.current.focus();
    } else if (index == 4) {
      input3Ref.current.focus();
    }
  }

  function handleNone(event, index) {
    event.preventDefault();
    let newInput = input;
    newInput[index].exists = false;
    newInput[index].position = false;
    setInput([...newInput]);
    submitRef.current.focus();
    validateInput();
  }

  function handleExists(event, index) {
    event.preventDefault();
    let newInput = input;
    newInput[index].exists = true;
    newInput[index].position = false;
    setInput([...newInput]);
    submitRef.current.focus();
    validateInput();
  }

  function handlePosition(event, index) {
    event.preventDefault();
    let newInput = input;
    newInput[index].exists = true;
    newInput[index].position = true;
    setInput([...newInput]);
    submitRef.current.focus();
    validateInput();
  }

  function handleClear(event) {
    event.preventDefault();
    setInput(defaultInput);
    input0Ref.current.focus();
  }

  function handleSubmit(event) {
    event.preventDefault();
    props.handleSubmit(input);
    let inputCopy = input;
    // handleClear(event)
    let newInput = defaultInput;
    inputCopy.forEach((cell, i) => {
      if (cell.position) {
        newInput[i].letter = cell.letter;
        newInput[i].exists = cell.exists;
        newInput[i].position = cell.position;
      }
    });
    setInput([...newInput]);
    setAllowSubmit(false);
    input0Ref.current.focus();
  }

  function handleSubmitKeyDown(event) {
    if (event.key === "Enter") {
      props.enableSubmit && handleSubmit(event);
    }
  }

  return (
    <div className="h-56 mt-16">
      <div className="gap-x-4 flex flex-row items-center">
        <FiTrash2
          className="w-8 h-8 text-red-600 cursor-pointer"
          onClick={(event) => handleClear(event)}
        ></FiTrash2>

        <form className="bg-slate-700 drop-shadow-2xl rounded-xl gap-x-3 flex flex-row justify-center p-2">
          <div className="relative">
            <input
              className={`${
                input[0].exists
                  ? input[0].position
                    ? "bg-green-600"
                    : "bg-yellow-600"
                  : "bg-slate-600"
              } text-center h-14 w-12 text-orange-100 text-3xl font-bold rounded-xl focus:outline-none focus:ring-2 ring-orange-600`}
              maxLength="1"
              type="text"
              value={input[0].letter}
              onChange={(event) => handleInputChange(event, 0)}
              ref={input0Ref}
            ></input>

            <FiRotateCcw
              style={{ top: "-2.5rem" }}
              className="left-3 absolute w-6 h-6 text-red-600 cursor-pointer"
              onClick={(event) => handleReset(event, 0)}
            ></FiRotateCcw>
            <div
              style={{ bottom: "-2.5rem" }}
              className="left-3 bg-slate-600 absolute w-6 h-6 rounded-lg cursor-pointer"
              onClick={(event) => handleNone(event, 0)}
            ></div>
            <div
              style={{ bottom: "-5.0rem" }}
              className=" left-3 absolute w-6 h-6 bg-yellow-600 rounded-lg cursor-pointer"
              onClick={(event) => handleExists(event, 0)}
            ></div>
            <div
              style={{ bottom: "-7.5rem" }}
              className=" left-3 absolute w-6 h-6 bg-green-600 rounded-lg cursor-pointer"
              onClick={(event) => handlePosition(event, 0)}
            ></div>
          </div>
          <div className="relative">
            <input
              className={`${
                input[1].exists
                  ? input[1].position
                    ? "bg-green-600"
                    : "bg-yellow-600"
                  : "bg-slate-600"
              } text-center h-14 w-12 text-orange-100 text-3xl font-bold rounded-xl focus:outline-none focus:ring-2 ring-orange-600`}
              maxLength="1"
              type="text"
              value={input[1].letter}
              onChange={(event) => handleInputChange(event, 1)}
              ref={input1Ref}
            ></input>

            <FiRotateCcw
              style={{ top: "-2.5rem" }}
              className="left-3 absolute w-6 h-6 text-red-600 cursor-pointer"
              onClick={(event) => handleReset(event, 1)}
            ></FiRotateCcw>
            <div
              style={{ bottom: "-2.5rem" }}
              className="left-3 bg-slate-600 absolute w-6 h-6 rounded-lg cursor-pointer"
              onClick={(event) => handleNone(event, 1)}
            ></div>
            <div
              style={{ bottom: "-5.0rem" }}
              className=" left-3 absolute w-6 h-6 bg-yellow-600 rounded-lg cursor-pointer"
              onClick={(event) => handleExists(event, 1)}
            ></div>
            <div
              style={{ bottom: "-7.5rem" }}
              className=" left-3 absolute w-6 h-6 bg-green-600 rounded-lg cursor-pointer"
              onClick={(event) => handlePosition(event, 1)}
            ></div>
          </div>
          <div className="relative">
            <input
              className={`${
                input[2].exists
                  ? input[2].position
                    ? "bg-green-600"
                    : "bg-yellow-600"
                  : "bg-slate-600"
              } text-center h-14 w-12 text-orange-100 text-3xl font-bold rounded-xl focus:outline-none focus:ring-2 ring-orange-600`}
              maxLength="1"
              type="text"
              value={input[2].letter}
              onChange={(event) => handleInputChange(event, 2)}
              ref={input2Ref}
            ></input>

            <FiRotateCcw
              style={{ top: "-2.5rem" }}
              className="left-3 absolute w-6 h-6 text-red-600 cursor-pointer"
              onClick={(event) => handleReset(event, 2)}
            ></FiRotateCcw>
            <div
              style={{ bottom: "-2.5rem" }}
              className="left-3 bg-slate-600 absolute w-6 h-6 rounded-lg cursor-pointer"
              onClick={(event) => handleNone(event, 2)}
            ></div>
            <div
              style={{ bottom: "-5.0rem" }}
              className=" left-3 absolute w-6 h-6 bg-yellow-600 rounded-lg cursor-pointer"
              onClick={(event) => handleExists(event, 2)}
            ></div>
            <div
              style={{ bottom: "-7.5rem" }}
              className=" left-3 absolute w-6 h-6 bg-green-600 rounded-lg cursor-pointer"
              onClick={(event) => handlePosition(event, 2)}
            ></div>
          </div>
          <div className="relative">
            <input
              className={`${
                input[3].exists
                  ? input[3].position
                    ? "bg-green-600"
                    : "bg-yellow-600"
                  : "bg-slate-600"
              } text-center h-14 w-12 text-orange-100 text-3xl font-bold rounded-xl focus:outline-none focus:ring-2 ring-orange-600`}
              maxLength="1"
              type="text"
              value={input[3].letter}
              onChange={(event) => handleInputChange(event, 3)}
              ref={input3Ref}
            ></input>

            <FiRotateCcw
              style={{ top: "-2.5rem" }}
              className="left-3 absolute w-6 h-6 text-red-600 cursor-pointer"
              onClick={(event) => handleReset(event, 3)}
            ></FiRotateCcw>
            <div
              style={{ bottom: "-2.5rem" }}
              className="left-3 bg-slate-600 absolute w-6 h-6 rounded-lg cursor-pointer"
              onClick={(event) => handleNone(event, 3)}
            ></div>
            <div
              style={{ bottom: "-5.0rem" }}
              className=" left-3 absolute w-6 h-6 bg-yellow-600 rounded-lg cursor-pointer"
              onClick={(event) => handleExists(event, 3)}
            ></div>
            <div
              style={{ bottom: "-7.5rem" }}
              className=" left-3 absolute w-6 h-6 bg-green-600 rounded-lg cursor-pointer"
              onClick={(event) => handlePosition(event, 3)}
            ></div>
          </div>
          <div className="relative">
            <input
              className={`${
                input[4].exists
                  ? input[4].position
                    ? "bg-green-600"
                    : "bg-yellow-600"
                  : "bg-slate-600"
              } text-center h-14 w-12 text-orange-100 text-3xl font-bold rounded-xl focus:outline-none focus:ring-2 ring-orange-600`}
              maxLength="1"
              type="text"
              value={input[4].letter}
              onChange={(event) => handleInputChange(event, 4)}
              ref={input4Ref}
            ></input>

            <FiRotateCcw
              style={{ top: "-2.5rem" }}
              className="left-3 absolute w-6 h-6 text-red-600 cursor-pointer"
              onClick={(event) => handleReset(event, 4)}
            ></FiRotateCcw>
            <div
              style={{ bottom: "-2.5rem" }}
              className="left-3 bg-slate-600 absolute w-6 h-6 rounded-lg cursor-pointer"
              onClick={(event) => handleNone(event, 4)}
            ></div>
            <div
              style={{ bottom: "-5.0rem" }}
              className=" left-3 absolute w-6 h-6 bg-yellow-600 rounded-lg cursor-pointer"
              onClick={(event) => handleExists(event, 4)}
            ></div>
            <div
              style={{ bottom: "-7.5rem" }}
              className=" left-3 absolute w-6 h-6 bg-green-600 rounded-lg cursor-pointer"
              onClick={(event) => handlePosition(event, 4)}
            ></div>
          </div>
        </form>

        <div className="group relative">
          <input
            type={""}
            className={`bg-transparent w-10 h-10 absolute ${
              props.enableSubmit && allowSubmit
                ? "cursor-pointer ring-green-600"
                : "cursor-not-allowed ring-red-600"
            } focus:outline-none focus:ring-2 ring-green-600 rounded-lg`}
            onClick={(event) =>
              props.enableSubmit && allowSubmit && handleSubmit(event)
            }
            ref={submitRef}
            tabIndex={0}
            onKeyPress={(event) => handleSubmitKeyDown(event)}
            maxLength={0}
          ></input>
          <FiSend
            className={`w-10 h-10 p-1 ${
              props.enableSubmit && allowSubmit
                ? "text-green-600"
                : "text-red-600"
            } focus:outline-none`}
          ></FiSend>
        </div>
      </div>
    </div>
  );
}
