import { Canvas } from "@react-three/fiber";
import Grid from "./components/Grid.js";
import Input from "./components/Input.js";
import { useState, useEffect } from "react";
import Answer_List from "./preprocessing/Answer_List";
import Letter_List from "./preprocessing/Letter_List";
import No_List from "./preprocessing/No_List";
import Letter_Position_List from "./preprocessing/Letter_Position_List";
import Sphere from "./Sphere";
export default function App() {
  const [attempts, setAttempts] = useState([]);
  const [inputPass, setInputPass] = useState();
  const [currentList, setCurrentList] = useState(Answer_List);
  const alphaMap = {
    A: 0,
    B: 1,
    C: 2,
    D: 3,
    E: 4,
    F: 5,
    G: 6,
    H: 7,
    I: 8,
    J: 9,
    K: 10,
    L: 11,
    M: 12,
    N: 13,
    O: 14,
    P: 15,
    Q: 16,
    R: 17,
    S: 18,
    T: 19,
    U: 20,
    V: 21,
    W: 22,
    X: 23,
    Y: 24,
    Z: 25,
  };

  function handleSubmit(input) {
    if (attempts.length <= 5) {
      setAttempts([...attempts, input]);
    } else {
      console.error("MAX ATTEMPTS");
    }
  }

  function intersect(a, b) {
    // console.log("Intersect");
    // console.log(a);
    // console.log(b);
    var t;
    if (b.length > a.length) (t = b), (b = a), (a = t); // indexOf to loop over shorter
    return a.filter(function (e) {
      return b.indexOf(e) > -1;
    });
  }

  function difference(a, b) {
    // console.log("difference");
    // console.log(a);
    // console.log(b);
    var t;
    if (b.length > a.length) (t = b), (b = a), (a = t); // indexOf to loop over shorter
    return a.filter(function (e) {
      return b.indexOf(e) == -1;
    });
  }

  useEffect(() => {
    //Game Logic
    if (attempts.length > 0) {
      let newList = currentList;
      let toggledSet = new Set();

      attempts[attempts.length - 1].forEach((cell, i) => {
        if (cell.exists) {
          if (cell.position) {
            //Green

            toggledSet.add(cell.letter);
          } else {
            //Yellow
            toggledSet.add(cell.letter);
          }
        }
        // console.log(toggledSet);
      });
      attempts[attempts.length - 1].forEach((cell, i) => {
        if (cell.exists) {
          if (cell.position) {
            //Green
            // console.log("Green");
            // console.log("Current List: ", currentList);
            let greenList =
              Letter_Position_List[alphaMap[cell.letter]][cell.letter][i][i];

            if (newList == Answer_List) {
              //   console.log("Debug1", greenList);
              newList = greenList;
            } else if (newList != greenList) {
              newList = intersect(newList, greenList);
            }
          } else {
            //Yellow
            // console.log("Yellow");

            let currentLetterList =
              Letter_List[alphaMap[cell.letter]][cell.letter];

            let currentLetterPositionList =
              Letter_Position_List[alphaMap[cell.letter]][cell.letter][i][i];

            let yellowList = difference(
              currentLetterList,
              currentLetterPositionList
            );
            newList = intersect(newList, yellowList);
          }
        } else {
          if (!toggledSet.has(cell.letter)) {
            // console.log("Black", cell);
            let blackList = No_List[alphaMap[cell.letter]][cell.letter];
            newList = intersect(newList, blackList);
          } else {
            let blackList =
              Letter_Position_List[alphaMap[cell.letter]][cell.letter][i][i];
            blackList = intersect(newList, blackList);
            newList = difference(newList, blackList);
          }
        }
        // console.log(toggledSet);
      });
      setCurrentList([...newList]);
    }
  }, [attempts]);

  function handleClear() {
    setAttempts([]);
    setCurrentList(Answer_List);
  }

  function handleClick(word) {
    setInputPass(word);
  }

  return (
    <div className="relative w-full min-h-screen flex flex-row justify-between bg-slate-900">
      <div className="w-full flex justify-start">
        <div className="w-3/4 flex flex-col">
          <Sphere wordList={currentList} handleClick={handleClick} />
        </div>
      </div>
      <div className="absolute top-1/2 right-1/4 transform translate-x-1/2 -translate-y-1/2">
        <div className="flex flex-col justify-center items-center">
          <Grid attempts={attempts} handleClear={handleClear} />
          <Input
            handleSubmit={handleSubmit}
            enableSubmit={attempts.length <= 5}
            inputPass={inputPass}
            // handleClickInput={handleClickInput}
          />
        </div>
      </div>
    </div>
  );
}
