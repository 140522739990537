import Letter from "./Letter.js"

export default function Word(props) {
	return (
		<div className="flex flex-row justify-content-center">
			<div
				className={`flex flex-row ${
					props.current ? "bg-orange-300" : "bg-slate-700"
				}   drop-shadow-2xl rounded-xl gap-x-3 p-2 justify-center`}
			>
				{props.word
					? props.word.map((digit, i) => (
							<Letter letter={digit.letter} exists={digit.exists} position={digit.position} key={i} />
					  ))
					: [...Array(5)].map((_, i) => <Letter key={i} />)}
			</div>
		</div>
	)
}
