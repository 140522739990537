import * as THREE from "three";
import { useRef, useState, useMemo, useEffect } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { Text, OrbitControls } from "@react-three/drei";

function Word({ children, ...props }) {
  const color = new THREE.Color();
  const fontProps = {
    fontSize: 2.5,
    letterSpacing: -0.05,
    lineHeight: 1,
    "material-toneMapped": false,
  };
  const ref = useRef();
  const meshRef = useRef();
  const [hovered, setHovered] = useState(false);
  const over = (e) => (e.stopPropagation(), setHovered(true));
  const out = () => setHovered(false);
  // Change the mouse cursor on hover
  useEffect(() => {
    if (hovered) document.body.style.cursor = "pointer";
    return () => (document.body.style.cursor = "auto");
  }, [hovered]);
  // Tie component to the render-loop
  useFrame(({ camera }) => {
    // Make text face the camera
    ref.current.quaternion.copy(camera.quaternion);
    // Animate font color
    ref.current.material.color.lerp(
      color.set(hovered ? "#fa2720" : "white"),
      0.1
    );
  }, 0);
  return (
    <Text
      ref={ref}
      onPointerOver={over}
      onPointerOut={out}
      onPointerDown={(e) => hovered && props.handleClick(children)}
      {...props}
      {...fontProps}
      children={children}
      occlude
    />
  );
}

function Cloud(props) {
  let count = props.count;
  let radius = props.radius;
  let wordList = props.wordList;
  const shuffleArray = (input) => {
    let array = [...input];
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  };

  const [wordListState, setWordList] = useState(wordList);
  const [wordListRandom, setRandomList] = useState(shuffleArray(wordList));
  useEffect(() => {
    setWordList(wordList);
    setRandomList(shuffleArray(wordList));
  }, [wordList]);

  const words = useMemo(() => {
    const temp = [];
    const spherical = new THREE.Spherical();
    const phiSpan = Math.PI / (count + 1);
    const thetaSpan = (Math.PI * 2) / count;
    for (let i = 1; i < count + 1; i++)
      for (let j = 0; j < count; j++)
        temp.push([
          new THREE.Vector3().setFromSpherical(
            spherical.set(radius, phiSpan * i, thetaSpan * j)
          ),
        ]);
    return temp;
  }, [count, radius]);

  return words.map(([pos], index) => (
    <Word
      key={index}
      position={pos}
      children={wordListRandom[index]}
      props={props}
      handleClick={props.handleClick}
    />
  ));
}

export default function Sphere(props) {
  let minCount = 10;
  let minRadius = 22;
  let wordList = props.wordList;
  if (wordList.length < 200) {
    minRadius = 6 + 20 * (wordList.length / 200);
    minCount = 1 + Math.ceil(wordList.length / minRadius);
  }

  const [viewSize, setSize] = useState();

  useEffect(() => {
    function handleResize() {
      if (window.innerHeight > window.innerWidth) {
        setSize(window.innerWidth);
      } else {
        setSize(window.innerHeight);
      }
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <Canvas
      dpr={[1, 2]}
      camera={{
        position: [0, 0, 50],
        fov: 50,
        near: 10,
        far: 1000,
      }}
    >
      <fog attach="fog" args={["#202025", 0, 80]} />
      <group scale={(viewSize / 1000) * 0.75} position={[0, 0, 0]}>
        <Cloud
          count={minCount}
          radius={minRadius}
          wordList={wordList}
          handleClick={props.handleClick}
        />
        <OrbitControls enablePan={false} minDistance={10} maxDistance={80} />
      </group>
    </Canvas>
  );
}
